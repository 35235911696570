import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

function Contact() {

    const form = useRef()
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(form.current);

        emailjs.sendForm('service_k8i73lz', 'template_bpckr69', form.current, 'VsOPAGLbbeuxoVFZl')
            .then((result) => {
                // console.log(result.text);
                if(result.text){
                    alert('You form has been Submited')
                    window.location.reload()
                } else {
                    alert('You form has been not Submited')
                    window.location.reload()
                }
            }, (error) => {
                // console.log(error.text);
                if(error.text){
                    alert('You form has been not Submited')
                    window.location.reload()
                } else {
                    alert('You form has been not Submited')
                    window.location.reload()
                }
            });

    }

    return (
        <>
            {/* BEGIN CONTAINER 
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>contact us</h2>
                        <ul className="uk-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li><span>Contact Us</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER
        BEGIN CONTACT */}
            <section className="contact sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid uk-grid-collapse">
                        <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                            <div className="uk-grid uk-grid-collapse uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-width-medium-4-6 uk-width-small-1-1 uk-width-1-1">
                                    <div className="sec-title">
                                        <h3>CONTACT US NOW</h3>
                                        <p>Get in touch with us today, and let's begin the journey toward creating a space that reflects your unique style and needs. Expect quick responses and humble, knowledgeable support every step of the way.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                {/* <a href="https://wa.me/919909953053?text="><span className="icon uk-icon-whatsapp"></span></a> */}
                                <span className="icon uk-icon-whatsapp"></span>
                                <p ><a href="https://wa.me/919909953053?text=" style={{ color: 'inherit' }}>+91 99099 53053</a></p>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                <span className="uk-icon-map-marker"></span>
                                <p><a style={{ color: 'inherit' }} href='https://www.google.com/maps/place/Shyamal+Industrial+Park/@22.1703428,70.6170138,1073m/data=!3m2!1e3!4b1!4m6!3m5!1s0x39582d3d359fadcd:0xeab9f521d38f410a!8m2!3d22.1703379!4d70.6195887!16s%2Fg%2F11v0jv8xrk?entry=ttu'>Shyamal Industrial Park, Lodikha, Rajkot</a></p>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                <span className="uk-icon-envelope"></span>
                                <p><a style={{ color: 'inherit' }} href="mailto:info@unilexinterio.com" >info@unilexinterio.com</a></p>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                {/* <a href="https://wa.me/919909953053?text="><span className="icon uk-icon-whatsapp"></span></a> */}
                                <span className="uk-icon-phone"></span>
                                <p ><a href="tel:+91909953053" style={{ color: 'inherit' }}>+91 99099 53053</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* END BEGIN CONTACT 
             BEGIN GOOGLE MAP */}

            {/* <div className="google-map-wrapper">
                <div className="google-map"
                    id="contact-page-google-map"
                    data-map-lat="41.008978"
                    data-map-lng="-74.242659"
                    data-map-zoom="9"
                    data-icon-path="images/map.png"
                    data-map-title="QTC MEDIA"
                    data-markers="contact@kitchendesign.com">
                </div>
            </div> */}

            <iframe
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                src="https://maps.google.com/maps?q=Shyamal%20Industrial%20Park,%20lodhika,%20near%20abhepar,%20Piparadi,%20Gujarat&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                width="100%"
                height="500"
                frameBorder="0"
                style={{ border: 0, marginTop: '8rem', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 7px 29px 0px' }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            />

            {/* END BEGIN GOOGLE MAP
        BEGIN SEND CONTACT  */}

            <section className="send-contact sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid uk-grid-collapse ">
                        <div className="uk-width-medium-4-6 uk-width-small-1-1 uk-width-1-1 uk-container-center">
                            <div className="sec-title">
                                <h3>SEND US A MESSAGE</h3>
                                <p>Ready to transform your space? Send us a message, and let's make your vision a reality</p>
                                <div className="border-img"></div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid uk-grid-collapse">
                        <div className="uk-width-medium-5-6 uk-width-small-1-1 uk-width-1-1 uk-container-center">
                            <form id="form_contact" className="uk-form kc-form-comment" onSubmit={handleSubmit} ref={form}>
                                <div className="uk-grid">
                                    <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-user"></i>
                                            <input className="input-text" name="name" type="text" placeholder="Your Name" required />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-phone"></i>
                                            <input className="input-text" name="phone" type="text" placeholder="Phone Number" required pattern='[0-9]{10}' />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-edit"></i>
                                            <input className="input-text" name="subject" type="text" placeholder="Subject" required />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-pencil"></i>
                                            <textarea className="input-text" name="comment" cols="5" rows="5" placeholder="Comment" required></textarea>
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="div-center">
                                            <button className="kc-button kc-button-primary" type="submit">SUBMIT MESSAGE</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* END BEGIN SEND CONTACT
        {/* END BEGIN GOOGLE MAP */}
        </>
    )
}

export default Contact
