import React from 'react'
import { Link } from 'react-router-dom'

function Career() {
  return (
    <div>
        {/* BEGIN CONTAINER
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>Career</h2>
                        <ul className="uk-breadcrumb">
                            <li> <Link to='/'>Home</Link> </li>
                            <li><span>Career</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER */}
    </div>
  )
}

export default Career
