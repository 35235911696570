import React from 'react'
import { Link } from 'react-router-dom'

function Notfound() {
  return (
    <div>
       {/* <!-- BEGIN BANNER --> */}
        <section className="inner-banner sec-padding">
            <div className="uk-container uk-container-center">
                <div className="box uk-clearfix">
                    <h2>404</h2>
                    <ul className="uk-breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Page not found</span></li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!-- END BEGIN BANNER -->
        <!-- BEGIN CONTAINER --> */}
        <section className="error-page sec-padding">
            <div className="uk-container uk-container-center uk-text-center">
                <div className="uk-grid">
                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                        <img className="img  uk-container-center" src="/assets/images/404.png" alt="" />
                    </div>
                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                        <div className="title">
                            <h1><span>OOPS!</span> PAGE NOT FOUND!</h1>
                            <p>The page you are looking for was moved, renamed or removed.</p>
                        </div>
                    </div>
                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1 uk-flex uk-flex-center">
                        <div className="menu">
                            <ul>
                                <li><Link to="/">GO HOME</Link></li>
                                <li><Link to="/contact">CONTACT US</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- END BEGIN CONTAINER --> */}
    </div>
  )
}

export default Notfound
