import React from 'react'
import { Link } from 'react-router-dom'

function Teams() {
    return (
        <div>
            {/* BEGIN CONTAINER
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>Teams</h2>
                        <ul className="uk-breadcrumb">
                            <li> <Link to='/'>Home</Link> </li>
                            <li><span>Teams</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER */}

            <section className="our-creative sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>our creative teams</h3>
                        <p>Donec vitae turpis volutpat, eleifend dolor vitae, consectetur erat vestibulum</p>
                        <div className="border-line"></div>
                    </div>
                    <div className="uk-slidenav-position" data-uk-slider="{autoplay: true , center: true}">
                        <div className="uk-slider-container">
                            <ul className="uk-slider uk-grid uk-grid-small uk-grid-width-medium-1-3 uk-grid-width-small-1-2 uk-grid-width-1-1">
                                <li>
                                    <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                        <img src="assets/images/our-creative-1-1.jpg" alt="" />
                                        <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-center uk-flex-bottom uk-animation-slide-top">
                                            <div className="box-content">
                                                <h3>jennifer Aniston</h3>
                                                <p>Web Desiger</p>
                                                <ul className="social">
                                                    <li><a href="#" target="_blank"><span className="uk-icon-facebook"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-vimeo"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-twitter"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-instagram"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-google"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                        <img src="assets/images/our-creative-1-2.jpg" alt="" />
                                        <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-center uk-flex-bottom uk-animation-slide-top">
                                            <div className="box-content">
                                                <h3>jennifer Aniston</h3>
                                                <p>Web Desiger</p>
                                                <ul className="social">
                                                    <li><a href="#" target="_blank"><span className="uk-icon-facebook"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-vimeo"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-twitter"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-instagram"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-google"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                        <img src="assets/images/our-creative-1-3.jpg" alt="" />
                                        <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-center uk-flex-bottom uk-animation-slide-top">
                                            <div className="box-content">
                                                <h3>jennifer Aniston</h3>
                                                <p>Web Desiger</p>
                                                <ul className="social">
                                                    <li><a href="#" target="_blank"><span className="uk-icon-facebook"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-vimeo"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-twitter"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-instagram"></span></a></li>
                                                    <li><a href="#" target="_blank"><span className="uk-icon-google"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a href="#" className="uk-slidenav uk-slidenav-contrast uk-slidenav-previous" data-uk-slider-item="previous"></a>
                        <a href="#" className="uk-slidenav uk-slidenav-contrast uk-slidenav-next" data-uk-slider-item="next"></a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Teams
