import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Data = [
    {
        ID: 1,
        Image: 'assets/images/blog-list-1.jpg',
        Title: "1stThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    },
    {
        ID: 2,
        Image: 'assets/images/blog-list-2.jpg',
        Title: "2ndThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    },
    {
        ID: 3,
        Image: 'assets/images/blog-list-1.jpg',
        Title: "3rdThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    },
    {
        ID: 4,
        Image: 'assets/images/blog-list-2.jpg',
        Title: "4thThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    },
    {
        ID: 5,
        Image: 'assets/images/blog-list-1.jpg',
        Title: "5rdThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    },
    {
        ID: 6,
        Image: 'assets/images/blog-list-2.jpg',
        Title: "6thThe Trick to Predicting 2016's Graphic Design TrendsEye",
        Date: "June 09, 2016",
        folder: 'Awesome Design',
        comment: '35',
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.",
        more: 'Read more'
    }
]

function Blog() {

    const [currentPage, setCurrentPage] = useState(1)

    const totalPage = Math.ceil(Data.length / 2);
    const startIndex = (currentPage - 1) * 2;
    const endIndex = startIndex + 2;

    const currentData = Data.slice(startIndex, endIndex);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        console.log('end', endIndex, 'start', Data.length);
        console.log(startIndex == 0)
    }, [endIndex, startIndex])




    return (
        <div>
            {/* BEGIN CONTAINER
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>blog list</h2>
                        <ul className="uk-breadcrumb">
                            <li> <Link to='/'>Home</Link> </li>
                            <li><span>Blog</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER */}

            {/* BEGIN BLOG LIST */}
            <section className="blog-list sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid">
                        <div className="uk-width-medium-3-4 uk-width-small-1-1 uk-width-1-1">

                            {
                                currentData.map(Items => (
                                    <div className="kc-blog-wrap" key={Items.ID}>
                                        <div className="blog-thumbnail">
                                            <img src={Items.Image} alt="" />
                                            <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                                <div className="overlay-content">
                                                    <span className="uk-icon-link"></span>
                                                </div>
                                            </div>
                                            <a className="uk-position-cover" href="#"></a>
                                        </div>
                                        <div className="blog-content">
                                            <h4>{Items.Title}</h4>
                                            <div className="blog-meta">
                                                <ul>
                                                    <li><span className="uk-icon-calendar"></span>{Items.Date}</li>
                                                    <li><span className="uk-icon-folder-o"></span>{Items.folder}</li>
                                                    <li><span className="uk-icon-comment-o"></span>{Items.comment}</li>
                                                </ul>
                                            </div>
                                            <p >{Items.description}</p>
                                        </div>
                                        <div className="blog-read-more">
                                            <Link to={Items.ID}>{Items.more}<span className="uk-icon-angle-double-right"></span></Link>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* <div className="kc-blog-wrap">
                                <div className="blog-thumbnail">
                                    <img src="assets/images/blog-list-1.jpg" alt="" />
                                    <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                        <div className="overlay-content">
                                            <span className="uk-icon-link"></span>
                                        </div>
                                    </div>
                                    <a className="uk-position-cover" href="#"></a>
                                </div>
                                <div className="blog-content">
                                    <h4>The Trick to Predicting 2016's Graphic Design TrendsEye</h4>
                                    <div className="blog-meta">
                                        <ul>
                                            <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                            <li><span className="uk-icon-folder-o"></span>Awesome Design</li>
                                            <li><span className="uk-icon-comment-o"></span>35</li>
                                        </ul>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.</p>
                                </div>
                                <div className="blog-read-more">
                                    <a href="#">Read more<span className="uk-icon-angle-double-right"></span></a>
                                </div>
                            </div>
                            <div className="kc-blog-wrap">
                                <div className="blog-thumbnail">
                                    <img src="assets/images/blog-list-2.jpg" alt="" />
                                    <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                        <div className="overlay-content">
                                            <span className="uk-icon-link"></span>
                                        </div>
                                    </div>
                                    <a className="uk-position-cover" href="#"></a>
                                </div>
                                <div className="blog-content">
                                    <h4>Beautifully Illustrated Graphics With Tips To Make You A Better</h4>
                                    <div className="blog-meta">
                                        <ul>
                                            <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                            <li><span className="uk-icon-folder-o"></span>Awesome Design</li>
                                            <li><span className="uk-icon-comment-o"></span>35</li>
                                        </ul>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.</p>
                                </div>
                                <div className="blog-read-more">
                                    <a href="#">Read more<span className="uk-icon-angle-double-right"></span></a>
                                </div>
                            </div>
                            <div className="kc-blog-wrap">
                                <div className="blog-thumbnail">
                                    <img src="assets/images/blog-list-3.jpg" alt="" />
                                    <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                        <div className="overlay-content">
                                            <span className="uk-icon-link"></span>
                                        </div>
                                    </div>
                                    <a className="uk-position-cover" href="#"></a>
                                </div>
                                <div className="blog-content">
                                    <h4>Small Apartment Design Tips - Solutions For Tiny Rooms</h4>
                                    <div className="blog-meta">
                                        <ul>
                                            <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                            <li><span className="uk-icon-folder-o"></span>Awesome Design</li>
                                            <li><span className="uk-icon-comment-o"></span>35</li>
                                        </ul>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae ligula facilisis, mattis massa in, commodo dolor. Donec iaculis nulla id quam cursus pulvinar. Nunc suscipit eget ante at placerat. Donec est nibh, viverra a auctor vitae, sagittis in dui. Fusce sem lectus, finibus vitae dolor ut, rutrum iaculis tellus.</p>
                                </div>
                                <div className="blog-read-more">
                                    <a href="#">Read more<span className="uk-icon-angle-double-right"></span></a>
                                </div>
                            </div> */}

                            <div className="pagination">
                                <ul>
                                    {!(startIndex == 0) &&
                                        <li><a onClick={() => handlePageChange(currentPage - 1)}>

                                            <span className="icon left"></span>
                                        </a></li>
                                    }
                                    {Array.from({ length: totalPage }).map((Items, index) => (
                                        <li key={index} className={currentPage == index + 1 ? 'active' : ''}>
                                            <a key={index} onClick={() => handlePageChange(index + 1)}
                                            // className={currentPage == index+1 ? 'active': ''}                                        
                                            >
                                                <span>{index + 1}</span>  {/* {index + 1 } */}
                                            </a>
                                        </li>

                                    ))}
                                    {!(endIndex == Data.length) &&
                                        <li><a onClick={() => handlePageChange(currentPage + 1)}>

                                            <span className="icon right"></span>
                                        </a></li>
                                    }
                                </ul>

                            </div>

                            {/* <div className="pagination">
                                <ul>
                                    <li><a href="#"><span className="icon left"></span></a></li>
                                    <li><a href="#"><span>1</span></a></li>
                                    <li><a href="#"><span>2</span></a></li>
                                    <li className="active"><a href="#"><span>3</span></a></li>
                                    <li><a href="#"><span>4</span></a></li>
                                    <li><a href="#"><span>5</span></a></li>
                                    <li><a href="#"><span className="icon right"></span></a></li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            {/* <div className="sidebar-widget">
                                <div className="uk-width-1-1 uk-width-small-1-1">
                                    <div className="sidebar-content">
                                        <form id="search" className="uk-form" action="#" method="post">
                                            <div className="form-icon">
                                                <a href="#"><span className="uk-icon-search"></span></a>
                                                <input type="text" placeholder="Search" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>CATEGORIES</h3>
                                    </div>
                                    <div className="sidebar-menu">
                                        <ul>
                                            <li><a href="#">Awesome Design</a></li>
                                            <li><a href="#">Tips and Tricks</a></li>
                                            <li><a href="#">Hacks Tips</a></li>
                                            <li><a href="#">Design Kitchen</a></li>
                                            <li><a href="#">Glass</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>RECENT POST</h3>
                                    </div>
                                    <div className="sidebar-post">
                                        <div className="img-recent">
                                            <a href="#"><img src="assets/images/recent-post-1-1.jpg" alt="" /></a>
                                        </div>
                                        <div className="content-recent">
                                            <a href="#"><h5>Steps to The Perfect Web Layout</h5></a>
                                            <ul>
                                                <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                                <li><span className="uk-icon-comment-o"></span>35</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sidebar-post">
                                        <div className="img-recent">
                                            <a href="#"><img src="assets/images/recent-post-1-2.jpg" alt="" /></a>
                                        </div>
                                        <div className="content-recent">
                                            <a href="#"><h5>Steps to The Perfect Web Layout</h5></a>
                                            <ul>
                                                <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                                <li><span className="uk-icon-comment-o"></span>35</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sidebar-post">
                                        <div className="img-recent">
                                            <a href="#"><img src="assets/images/recent-post-1-3.jpg" alt="" /></a>
                                        </div>
                                        <div className="content-recent">
                                            <a href="#"><h5>Steps to The Perfect Web Layout</h5></a>
                                            <ul>
                                                <li><span className="uk-icon-calendar"></span>June 09, 2016</li>
                                                <li><span className="uk-icon-comment-o"></span>35</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>TAGS</h3>
                                    </div>
                                    <div className="tags-recent">
                                        <a href="#">Chair</a>
                                        <a href="#">Table</a>
                                        <a href="#">Kitchen Room</a>
                                        <a href="#">Bathroom</a>
                                        <a href="#">Glass</a>
                                        <a href="#">Design</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END BEGIN LOG LIST
            BEGIN CAROUSEL */}
            <section className="carousel sec-padding sec-background">
                <div className="uk-container uk-container-center">
                    <div className="uk-slidenav-position" data-uk-slider="{center:true, autoplay: true}">
                        <div className="uk-slider-container">
                            <ul className="uk-slider uk-grid-width-medium-1-5 uk-grid-width-smal-1-3 uk-grid-width-1-1">
                                <li><img src="assets/images/carousel/2-1.png" alt="" /></li>
                                <li><img src="assets/images/carousel/2-2.png" alt="" /></li>
                                <li><img src="assets/images/carousel/2-3.png" alt="" /></li>
                                <li><img src="assets/images/carousel/2-4.png" alt="" /></li>
                                <li><img src="assets/images/carousel/2-5.png" alt="" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END BEGIN CAROUSEL            
            END BEGIN CONTAINER */}
        </div>
    )
}

export default Blog
