import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import Blog from "./Pages/Blog";
import Career from "./Pages/Career";
import Certificate from "./Pages/Certificate";
import Getaquote from "./Pages/Getaquote";
import Teams from "./Pages/Teams";
import Notfound from "./Pages/Notfound";

// import { Header, Footer, Home, About, Contact, Products, Blog, Career, Certificate } from './Pages'


function App() {
  return (

    <Router>

      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/products' element={<Products />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/career' element={<Career />} />
        <Route path='/certificate' element={<Certificate />} />
        <Route path='/getAQuote' element={<Getaquote />} />
        <Route path='/teams' element={<Teams />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Notfound/> } />
      </Routes>
      <Footer />

    </Router>
  );
}

export default App;
