import React from 'react'
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
// import Wrapper from "./components/Wrapper";
// import Title from "./components/Title";
// import Subtitle from "./components/Subtitle";

const slider21 = "assets/images/slider-2-1.jpg";
const slider22 = "assets/images/slider-2-2.jpg";
const slider23 = "assets/images/slider-2-3.jpg";
const slider24 = "assets/images/slider-2-4.jpg";
const slider25 = "assets/images/slider-2-5.jpg";
const slider26 = "assets/images/slider-2-6.jpg";
// const craterRock = "https://i.imgur.com/8DYumaY.jpg";
// const giauPass = "https://i.imgur.com/8IuucQZ.jpg";


function Home() {
    return (
        <>
            {/* <!--END BEGIN HEADER -->
         <!-- BEGIN CONTAINER -->
        <!-- BEGIN BLOCK SLIDER --> */}
            <section className="slider">
                <div id="slider1" className="rev_slider" data-version="5.0">
                    <HeroSlider
                        height={"100vh"}
                        autoplay
                        controller={{
                            initialSlide: 1,
                            slidingDuration: 500,
                            slidingDelay: 100,
                            onSliding: (nextSlide) =>
                                console.debug("onSliding(nextSlide): ", nextSlide),
                            onBeforeSliding: (previousSlide, nextSlide) =>
                                console.debug(
                                    "onBeforeSliding(previousSlide, nextSlide): ",
                                    previousSlide,
                                    nextSlide
                                ),
                            onAfterSliding: (nextSlide) =>
                                console.debug("onAfterSliding(nextSlide): ", nextSlide)
                        }}
                    >
                        {/* <Overlay>
                            <div>
                                <p>Hiiiii</p>

                            </div>
                        </Overlay> */}

                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="Giau Pass - Italy"
                            background={{
                                backgroundImageSrc: slider21
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>As we evolve our homes shown too</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>


                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="County Clare - Ireland"
                            background={{
                                backgroundImageSrc: slider22
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>Kitchen</span>
                                        <br />
                                        <span>the heart of the home</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>
                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="County Clare - Ireland"
                            background={{
                                backgroundImageSrc: slider23
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>Modern Closet Ideas to Redefine</span>
                                        <br />
                                        <span>Your Home Organization</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>
                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="County Clare - Ireland"
                            background={{
                                backgroundImageSrc: slider24
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>Modern cabinet/wardrobe design</span>
                                        <br />
                                        <span>ideas for your home</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>
                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="County Clare - Ireland"
                            background={{
                                backgroundImageSrc: slider25
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>Minimalist bedroom</span>
                                        <br />
                                        <span>Interior Design</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>
                        <Slide
                            shouldRenderMaskshouldRenderMask
                            label="County Clare - Ireland"
                            background={{
                                backgroundImageSrc: slider26
                            }}
                        >
                            <div className="sp-container">
                                <div className="sp-content">
                                    <div className="sp-globe"></div>
                                    <h2 className="frame-5">
                                        <span>Modern Glass Decor for Bed Room</span>
                                    </h2>
                                    {/* <a className="sp-circle-link" href="https://nick.mkrtchyan.ga">by Nick</a> */}
                                </div>
                            </div>
                        </Slide>


                        {/* <MenuNav /> */}
                    </HeroSlider>
                </div>
            </section>
            {/* <!-- END BEGIN BLOCK SLIDER -->
        <!-- BEGIN BLOCK WORK ART --> */}
            <section className="work-art sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>how we WORK ART</h3>
                        <p>PRESENTING STATE OF THE ART CUSTOMIZED MODULAR FURNITURE</p>
                        <div className="border-line"></div>
                    </div>
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                <div className="box-icon">
                                    <span className="icon uk-icon-search"></span>
                                </div>
                                <div className="box-content">
                                    <h3>search design</h3>
                                    <p>Unilex Interio, India's premier modular furniture provider since 2022, offers top-quality, eco-friendly solutions with a commitment to transparency and customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                <div className="box-icon">
                                    <span className="icon uk-icon-download"></span>
                                </div>
                                <div className="box-content">
                                    <h3>book your design</h3>
                                    <p>Book your innovative modular design with Unilex Interio, India's trusted leader in superior technology, eco-friendly practices, and exceptional customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box">
                                <div className="box-icon">
                                    <span className="icon uk-icon-cogs"></span>
                                </div>
                                <div className="box-content">
                                    <h3>we MAKE your design</h3>
                                    <p>We MAKE your design: Unilex Interio, your trusted source for top-quality, eco-friendly modular furniture, blending innovation with customer satisfaction since 2022.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- END BEGIN BLOCK WORK ART -->

              <!-- BEGIN BLOCK WATCH VIDEO --> */}
            <section className="watch-video sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box">
                        {/* <div className="box-icon">
                            <a href="#"><span className="icon uk-icon-play"></span></a>
                        </div> */}
                        <div className="box-content">
                            <h4>We Are Professional</h4>
                            <h3>see how we make you happy</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- END BEGIN BLOCK OUR CLIENTS -->

        <!-- BEGIN BLOCK DESIGNS --> */}
            <section className="designs sec-padding sec-background" style={{ padding: '50px 0' }}>
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>best designs</h3>
                        <p>Your trusted source for top-quality, eco-friendly modular furniture since 2022, where innovation meets customer satisfaction.</p>
                        <div className="border-line"></div>
                    </div>
                </div>
                <div data-uk-slideset="{animation: 'scale', medium:4, small:2}">
                    <div className="uk-flex uk-flex-center filter-design-sc">
                        <ul id="filter" className="filter">
                            {/* <li className="check-class kc-filter-all uk-active" data-uk-filter=""><a href="#">Kitchen</a></li> */}
                            <li className="check-class" data-uk-filter="house" ><a href="#">Modular Kitchen</a></li>
                            <li className="check-class" data-uk-filter="garacer" ><a href="#">Wardrobe</a></li>
                            <li className="check-class" data-uk-filter="pool" ><a href="#">Modern Beds</a></li>
                            {/* <li className="check-class" data-uk-filter="villa" ><a href="#">Villa</a></li> */}
                        </ul>
                    </div>
                    <div className="uk-slidenav-position content-design-sc">
                        <ul className="uk-slideset uk-grid uk-grid-collapse uk-flex-center uk-grid-width-1-1 uk-grid-width-medium-1-4 uk-grid-width-small-1-2">
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        {/* <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/1-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                            <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        {/* <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/2-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                            <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_5.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_6.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_7.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_8.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/1-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                               <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/2-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                            <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            {/* <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/1-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_5.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_6.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_7.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_8.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="pool" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="pool" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                            {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li data-uk-filter="villa" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                               <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="uk-container uk-container-center pagination-design-sc">
                        <div className="uk-flex uk-flex-center uk-flex-middle">
                            <div className="slideset-arrow">
                                <a href="#" className="uk-slidenav uk-slidenav-previous hidden" data-uk-slideset-item="previous"></a>
                                <a href="#" className="uk-slidenav uk-slidenav-next hidden" data-uk-slideset-item="next"></a>
                                <ul id="has_class" className="uk-slideset-nav uk-dotnav uk-flex-center"></ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!-- END BEGIN BLOCK DESIGNS -->
        <!-- BEGIN BLOCK OUR CLIENTS --> */}
            {/* <section className="clients-say sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>our clients say</h3>
                        <p>Donec vitae turpis volutpat, eleifend dolor vitae, consectetur erat vestibulum</p>
                        <div className="border-line"></div>
                    </div>
                    <div className="uk-slidenav-position" data-uk-slideshow="{autoplay: true}">
                        <ul className="uk-slideshow">
                            <li>
                                <div className="item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempor orci metus, quis facilisis enim commodo in. In finibus neque vitae nunc facilisis faucibus. Nulla quis nulla quis eros vehicula condimentum eleifend eu nulla. Sed finibus leo nec aliquam semper. Finibus neque vitae nunc facilisis faucibus. </p>
                                    <div className="author">John Smith</div>
                                    <div className="city">From United States</div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                                    <div className="author">David Gomez</div>
                                    <div className="city">From United States</div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. </p>
                                    <div className="author">Ben Lee</div>
                                    <div className="city">From United States</div>
                                </div>
                            </li>
                        </ul>
                        <div className="slideshow-thumb">
                            <ul className="uk-flex uk-flex-middle uk-flex-center">
                                <li data-uk-slideshow-item="0">
                                    <a href="#"><img src="assets/images/clients/2-2.jpg" alt="" /></a>
                                </li>
                                <li data-uk-slideshow-item="1">
                                    <a href="#"><img src="assets/images/clients/2-1.jpg" alt="" /></a>
                                </li>
                                <li data-uk-slideshow-item="2">
                                    <a href="#"><img src="assets/images/clients/2-3.jpg" alt="" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END BEGIN BLOCK OUR CLIENTS -->
      
        <!-- BEGIN BLOCK OUR CREATIVE TEAMS --> */}

            {/* <!-- END BEGIN BLOCK OUR CREATIVE TEAMS -->
        <!-- BEGIN BLOCK PRODUCT --> */}
            {/* <section className="creative-product sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>creative products</h3>
                        <p>Donec vitae turpis volutpat, eleifend dolor vitae, consectetur erat vestibulum</p>
                        <div className="border-line"></div>
                    </div>
                    <div className="silder-product" data-uk-slideset="{small: 2, medium: 3, animation: 'scale'}">
                        <div className="uk-slidenav-position uk-margin">
                            <ul className="uk-slideset uk-grid uk-flex-center uk-grid-width-1-1 uk-grid-width-medium-1-3 uk-grid-width-small-1-2">
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-1.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>canon powershot</h3>
                                            <div className="product-star" title="Rated 3 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$199</span>
                                                <span className="sale">$250</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-2.jpg" alt="" />
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>creative chair cover</h3>
                                            <div className="product-star" title="Rated 5 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$209</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-3.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>awesome white chair</h3>
                                            <div className="product-star" title="Rated 4 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$299</span>
                                                <span className="sale">$350</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-1.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>canon powershot</h3>
                                            <div className="product-star" title="Rated 3 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$199</span>
                                                <span className="sale">$250</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-2.jpg" alt="" />
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>creative chair cover</h3>
                                            <div className="product-star" title="Rated 5 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$209</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-3.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>awesome white chair</h3>
                                            <div className="product-star" title="Rated 4 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$299</span>
                                                <span className="sale">$350</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-1.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>canon powershot</h3>
                                            <div className="product-star" title="Rated 3 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$199</span>
                                                <span className="sale">$250</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-2.jpg" alt="" />
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>creative chair cover</h3>
                                            <div className="product-star" title="Rated 5 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$209</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-3.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>awesome white chair</h3>
                                            <div className="product-star" title="Rated 4 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$299</span>
                                                <span className="sale">$350</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-1.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>canon powershot</h3>
                                            <div className="product-star" title="Rated 3 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$199</span>
                                                <span className="sale">$250</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-2.jpg" alt="" />
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>creative chair cover</h3>
                                            <div className="product-star" title="Rated 5 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$209</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="product-item">
                                        <div className="box-img uk-overlay uk-overlay-hover uk-animation-hover">
                                            <img src="assets/images/product/1-3.jpg" alt="" />
                                            <div className="product-overlay">
                                                <span className="sale">SALE</span>
                                            </div>
                                            <div className="uk-overlay-panel uk-overlay-background uk-animation-scale-up uk-flex uk-flex-center uk-flex-middle">
                                                <div className="box-icon">
                                                    <a href="#"><span className="icon uk-icon-shopping-cart uk-animation-slide-left"></span></a>
                                                    <a href="#"><span className="icon uk-icon-star uk-animation-slide-right"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content div-center">
                                            <h3>awesome white chair</h3>
                                            <div className="product-star" title="Rated 4 out of 5">
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star"></i>
                                                <i className="icon uk-icon-star-o"></i>
                                            </div>
                                            <div className="product-number">
                                                <span className="price">$299</span>
                                                <span className="sale">$350</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-container uk-container-center">
                            <div className="uk-flex uk-flex-center uk-flex-middle">
                                <div className="slideset-arrow">
                                    <a href="#" className="uk-slidenav uk-slidenav-previous" data-uk-slideset-item="previous"></a>
                                    <a href="#" className="uk-slidenav uk-slidenav-next" data-uk-slideset-item="next"></a>
                                    <ul className="uk-slideset-nav uk-dotnav uk-flex-center"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END BEGIN BLOCK PRODUCT -->
        <!-- BEGIN BLOCK FROM OUR BLOG --> */}
            {/* <section className="our-blog sec-padding sec-background">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>from our blog</h3>
                        <p>Donec vitae turpis volutpat, eleifend dolor vitae, consectetur erat vestibulum</p>
                        <div className="border-line"></div>
                    </div>
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                            <div className="box-blog div-center">
                                <div className="box-img">
                                    <img src="assets/images/blog-1-1.jpg" alt="" />
                                    <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                        <div className="overlay-content">
                                            <span className="uk-icon-link"></span>
                                        </div>
                                    </div>
                                    <a className="uk-position-cover" href="#"></a>
                                </div>
                                <div className="box-content">
                                    <h3>20 Steps to The Perfect Website Layout</h3>
                                    <ul className="meta">
                                        <li>
                                            <span className="icon uk-icon-calendar"></span> June 09, 2016
                                        </li>
                                        <li>
                                            <span className="icon uk-icon-folder-o"></span> Awesome Design
                                        </li>
                                        <li>
                                            <span className="icon uk-icon-comment-o"></span> 35
                                        </li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempor metus, quis facilisis enim commodo in. In finibus neque vitae nunc facilisis faucibus.</p>
                                    <div className="div-center">
                                        <a href="#" className="kc-button kc-button-primary kc-button-white">READ MORE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                            <div className="box-blog">
                                <div className="box-img">
                                    <img src="assets/images/blog-1-2.jpg" alt="" />
                                    <div className="overlay uk-overlay-panel uk-flex uk-flex-center uk-flex-middle">
                                        <div className="overlay-content">
                                            <span className="uk-icon-link"></span>
                                        </div>
                                    </div>
                                    <a className="uk-position-cover" href="#"></a>
                                </div>
                                <div className="box-content div-center">
                                    <h3>20 Steps to The Perfect Website Layout</h3>
                                    <ul className="meta">
                                        <li>
                                            <span className="icon uk-icon-calendar"></span> June 09, 2016
                                        </li>
                                        <li>
                                            <span className="icon uk-icon-folder-o"></span> Awesome Design
                                        </li>
                                        <li>
                                            <span className="icon uk-icon-comment-o"></span> 35
                                        </li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempor metus, quis facilisis enim commodo in. In finibus neque vitae nunc facilisis faucibus.</p>
                                    <div className="div-center">
                                        <a href="#" className="kc-button kc-button-primary kc-button-white">READ MORE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- END BEGIN BLOCK FROM OUR BLOG -->
        <!-- BEGIN BLOCK SEND US A MESSAGE --> */}
            {/* <!-- END BEGIN BLOCK SEND US A MESSAGE -->
        <!-- BEGIN BLOCK CAROUSEL --> */}
            <section className="carousel sec-padding sec-background">
                <div className="uk-container uk-container-center">
                    <div className="uk-slidenav-position" data-uk-slider="{center:true, autoplay: true}">
                        <div className="uk-slider-container">
                            <ul className="uk-slider uk-grid-width-medium-1-5 uk-grid-width-smal-1-3 uk-grid-width-1-1">
                                <li><img src="assets/images/carousel/Image_1.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_2.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_3.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_4.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_5.png" alt="" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
