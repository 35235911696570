import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {

    return (
        <>
            {/* <!-- END BEGIN BLOCK CAROUSEL -->
        <!-- END BEGIN BLOCK CONTENT -->
        <!-- BEGIN FOOTER --> */}
            <footer className="footer sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="footer-widget notitle about-widget">
                                <div className="title">
                                    <h3>ABOUT US</h3>
                                </div>
                                <img className="logo-footer" src="assets/images/logo.png" alt="" />
                                <p>Elevate Your Living Spaces to New Heights with Unilex Interio: Where Unwavering Quality Meets Trust, Pioneering Excellence Since 2022.</p><br></br>
                                {/* <ul className="social-footer">
                                    <li><a href="#" target="_blank"><span className="uk-icon-facebook"></span></a></li>
                                    <li><a href="#" target="_blank"><span className="uk-icon-vimeo"></span></a></li>
                                    <li><a href="#" target="_blank"><span className="uk-icon-twitter"></span></a></li>
                                    <li><a href="#" target="_blank"><span className="uk-icon-instagram"></span></a></li>
                                    <li><a href="#" target="_blank"><span className="uk-icon-google"></span></a></li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="uk-grid">
                                <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                    <div className="footer-widget post-link-widget">
                                        <div className="title">
                                            <h3>INFOMATION</h3>
                                        </div>
                                        <ul>
                                            <li><Link to='/about'>About Us</Link></li>
                                            <li><Link to='/contact'>Contact Us</Link></li>
                                        </ul>
                                    </div>
                                    <br></br>
                                </div>
                                
                                <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                    <div className="footer-widget post-link-widget">
                                        <div className="title">
                                            <h3>LINKS</h3>
                                        </div>
                                        <ul>
                                            <li><Link to='/products'>Products</Link></li>
                                            {/* <li><Link to='/blog'>Blog</Link></li> */}
                                            <li><Link to='/getAQuote'>Get A Quote</Link></li>
                                            {/* <li><Link to='/teams'>Teams</Link></li> */}
                                            {/* <li><Link to='/career'>Career</Link></li> */}
                                            {/* <li><Link to='/certificate'>Certificate</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="footer-widget post-img-widget">
                                <div className="title">
                                    <h3>FROM BLOG</h3>
                                </div>
                                <ul>
                                    <li>
                                        <div className="box-img">
                                            <a href="#"><img src="assets/images/footer-blog-2-1.jpg" alt="" /></a>
                                        </div>
                                        <div className="box-content">
                                            <a href="#">Effective Web Design Principles You Should Know</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-img">
                                            <a href="#"><img src="assets/images/footer-blog-2-2.jpg" alt="" /></a>
                                        </div>
                                        <div className="box-content">
                                            <a href="#">20 Steps to The Perfect Website <br />Layout</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            <div className="footer-bottom">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid uk-grid-small">
                        <div className="uk-width-medium-4-10 uk-width-small-1-1 uk-width-1-1">
                            <div className="left">
                                <p >Copyright &copy; 2023 <Link to='https://technozions.com/'>Technozions Private Limited</Link> All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kc_backtop" className="kc-bt-show" data-uk-smooth-scroll><i className="uk-icon-arrow-up"></i></div>
        </>
    )
}

export default Footer
