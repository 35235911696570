import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const menu = [
   {
      title: 'Home',
      initialPath: '/',
   },
   {
      title: 'Products',
      initialPath: '/products',
   },
   {
      title: 'Get A Quote',
      initialPath: '/getAQuote',
   },
   {
      title: 'About',
      initialPath: '/about',
   },
   {
      title: 'Contact',
      initialPath: '/contact',
   },
]

function Header() {

   const [menuVisible, setMenuVisible] = useState(false)

   const handleMenuToggleClick = () => {
      setMenuVisible(!menuVisible)
   }

   return (
      <>
         {/* BEGIN HEADER  */}



         <header className="home-one">
            <div className="top-bar">
               <div className="uk-container uk-container-center">
                  <div className="uk-grid uk-grid-collapse">
                     <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1" style={{ width: '190px' }} >
                        <div className="box uk-flex uk-flex-middle uk-flex-left" >
                           <Link to="/"><img src="assets/images/logo-home-1.png" alt="logo" /></Link>
                        </div>
                     </div>
                     <div className="uk-width-medium-3-4 uk-width-small-1-1 uk-width-1-1 uk-hidden-small">
                        <div className="box uk-flex uk-flex-middle uk-flex-right">
                           <ul className="uk-subnav uk-subnav-line">
                              <li ><span className="icon uk-icon-map-marker" ></span><a href='https://www.google.com/maps/place/Shyamal+Industrial+Park/@22.1703428,70.6170138,1073m/data=!3m2!1e3!4b1!4m6!3m5!1s0x39582d3d359fadcd:0xeab9f521d38f410a!8m2!3d22.1703379!4d70.6195887!16s%2Fg%2F11v0jv8xrk?entry=ttu'>Shyamal Industrial Park, Lodikha, Rajkot</a></li>
                              <li><span className="icon uk-icon-envelope"></span> <a href="mailto:info@unilexinterio.com" >info@unilexinterio.com</a></li>
                              <li><a href="https://wa.me/919909953053?text="><span className="icon uk-icon-whatsapp"></span></a> {/*<span className="icon uk-icon-phone"></span> */}<a href='tel:+91909953053'>+91 99099 53053</a></li> 
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="header">
               <div className="uk-container uk-container-center">
                  <nav className="kc-navbar uk-clearfix">
                     <ul className="uk-navbar-nav menu-primary uk-hidden-medium uk-hidden-small">
                        <li><Link to='/'>HOME</Link></li>
                        <li><Link to='/products'>PRODUCTS</Link></li>
                        {/* <li><Link to='/career'>CAREER</Link></li> */}
                        {/* <li><Link to='/certificate'>CERTIFICATE</Link></li> */}
                        <li><Link to='/getAQuote'>Get A Quote</Link></li>
                        {/* <li><Link to='/teams'>TEAMS</Link></li> */}
                        {/* <li><Link to='/blog'>BLOG</Link></li> */}
                        <li><Link to='/about'>ABOUT</Link></li>
                        <li><Link to='/contact'>CONTACT</Link></li>
                     </ul>
                     <div className="uk-navbar-flip uk-clearfix">
                        <ul className="uk-float-right">
                           <li className="uk-hidden-large">
                              <a onClick={handleMenuToggleClick}><span className="icon uk-icon-reorder"></span></a>
                           </li>
                        </ul>
                     </div>
                  </nav>
               </div>

               <div className="menu-media" style={{ display: menuVisible ? 'block' : 'none' }}>
                  <nav className="nav-holder">
                     <ul className="nav">
                        {menu.map((Items, index) => (
                           <li key={Items.initialPath} className="has-submenu">
                           <a href={Items.initialPath}>{Items.title}</a>
                         </li>
                        ))}
                     </ul>
                  </nav>
               </div>

            </div>
         </header>




      </>
   )
}

export default Header 
