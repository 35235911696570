import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

function Getaquote() {

    // const [values, setValues] = useState({})
    const form = useRef()

    // const handleChnage = (e) => {
    //     const {name, value } = e.target
    //     setValues(values => ({ ...values, [name]:value }))
    // }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(form.current);

        // return

        emailjs.sendForm('service_k8i73lz', 'template_tq3itcb', form.current, 'VsOPAGLbbeuxoVFZl')
            .then((result) => {
                // console.log(result.text);
                if (result.text) {
                    alert('You form has been Submited')
                    window.location.reload()
                } else {
                    alert('You form has been not Submited')
                    window.location.reload()
                }
            }, (error) => {
                // console.log(error.text);
                if (error.text) {
                    alert('You form has been not Submited')
                    window.location.reload()
                } else {
                    alert('You form has been not Submited')
                    window.location.reload()
                }
            });
    }


    return (
        <div>
            {/* BEGIN CONTAINER 
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>Get A Quote</h2>
                        <ul className="uk-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li><span>Get A Quote</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER
        BEGIN CONTACT */}

            <section className="send-contact sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid uk-grid-collapse ">
                        <div className="uk-width-medium-4-6 uk-width-small-1-1 uk-width-1-1 uk-container-center">
                            <div className="sec-title">
                                <h3>Get a Quote for Your new Modular Kitchen</h3>
                                <p>Tell us about your requirements and we'll get back to you with a quote.</p>
                                <div className="border-img"></div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid uk-grid-collapse">
                        <div className="uk-width-medium-5-6 uk-width-small-1-1 uk-width-1-1 uk-container-center">
                            <form id="form_contact" className="uk-form kc-form-comment" onSubmit={handleSubmit} ref={form}>
                                <div className="uk-grid">

                                    <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-user"></i>
                                            <input className="input-text" name="fname" type="text" placeholder="First Name" required />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-user"></i>
                                            <input className="input-text" name="lname" type="text" placeholder="Last Name" required />
                                        </div>
                                    </div>

                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-home"></i>
                                            <input className="input-text" name="Kitchen" type="text" placeholder="Looking for Home, Office, Kitchen or Other" required />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-envelope-o"></i>
                                            <input className="input-text" name="email" type="email" placeholder="Email" required />
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-phone"></i>
                                            <input className="input-text" name="phone" type="text" placeholder="Phone Number" required pattern='[0-9]{10}' />
                                        </div>
                                    </div>

                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="uk-form-icon">
                                            <i className="uk-icon-pencil"></i>
                                            <textarea className="input-text" name="comment" cols="5" rows="5" placeholder="Massage" required ></textarea>
                                        </div>
                                    </div>
                                    <div className="uk-width-medium-1-1 uk-width-small-1-1 uk-width-1-1">
                                        <div className="div-center">
                                            <button className="kc-button kc-button-primary" type="submit">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Getaquote
