import React from 'react'
import { Link } from 'react-router-dom'

function Products() {
    return (
        <div>
            {/* BEGIN CONTAINER
        BEGIN BANNER */}
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>products list</h2>
                        <ul className="uk-breadcrumb">
                            <li><Link to='/' >Home</Link></li>
                            <li><span>Products</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* END BEGIN BANNER
        BEGIN PRODUCTS LIST  */}
            {/* <section className="product-list sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <form id="search" className="uk-form" action="#" method="post">
                                        <div className="form-icon">
                                            <a href="#"><span className="uk-icon-search"></span></a>
                                            <input type="text" placeholder="Search" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>CATEGORIES</h3>
                                    </div>
                                    <div className="sidebar-menu">
                                        <ul>
                                            <li><a href="#">Chair</a></li>
                                            <li><a href="#">Table</a></li>
                                            <li><a href="#">Shoe</a></li>
                                            <li><a href="#">Hat</a></li>
                                            <li><a href="#">Glass</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>PRICE</h3>
                                    </div>
                                    <div className="sidebar-product">
                                        <div className="sidebar-post">
                                            <div className="slider-range">
                                                <>
                                                    <input type="text" id="min" readonly />
                                                    <input type="text" id="max" readonly />
                                                </>
                                                <div id="slider-range"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>RECENT PRODUCT</h3>
                                    </div>
                                    <div className="sidebar-product">
                                        <div className="sidebar-post">
                                            <div className="img-recent">
                                                <a href="#"><img src="assets/images/recent-product-1.jpg" alt="" /></a>
                                            </div>
                                            <div className="content-recent">
                                                <a href="#"><h5>CREATIVE CHAIR YELLLOW</h5></a>
                                                <p>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                </p>
                                                <h6>$199<span>$250</span></h6>
                                            </div>
                                        </div>
                                        <div className="sidebar-post">
                                            <div className="img-recent">
                                                <a href="#"><img src="assets/images/recent-product-2.jpg" alt="" /></a>
                                            </div>
                                            <div className="content-recent">
                                                <a href="#"><h5>CREATIVE CHAIR YELLLOW</h5></a>
                                                <p>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                </p>
                                                <h6>$199<span>$250</span></h6>
                                            </div>
                                        </div>
                                        <div className="sidebar-post">
                                            <div className="img-recent">
                                                <a href="#"><img src="assets/images/recent-product-3.jpg" alt="" /></a>
                                            </div>
                                            <div className="content-recent">
                                                <a href="#"><h5>CREATIVE CHAIR YELLLOW</h5></a>
                                                <p>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                    <span className="uk-icon-star"></span>
                                                </p>
                                                <h6>$199<span>$250</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget">
                                <div className="sidebar-content">
                                    <div className="sidebar-title">
                                        <h3>TAGS</h3>
                                    </div>
                                    <div className="tags-recent">
                                        <a href="#">Chair</a>
                                        <a href="#">Table</a>
                                        <a href="#">Kitchen Room</a>
                                        <a href="#">Bathroom</a>
                                        <a href="#">Glass</a>
                                        <a href="#">Design</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-3-4 uk-width-small-1-1 uk-width-1-1">
                            <div className="product-box">
                                <div className="kc-product-wrap">
                                    <div className="box-product">
                                        <div className="uk-grid uk-grid-collapse">
                                            <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="thumbnai-product">
                                                    <a href="#"><img src="assets/images/product-list-1.jpg" alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="uk-width-medium-2-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="conten-product">
                                                    <h3>CREATIVE CHAIR YELLOW</h3>
                                                    <p>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                    </p>
                                                    <h5>$199<span>$250</span></h5>
                                                    <p className="text-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent velit odio, id porta orci vulputate a. Donec lorem purus, dignissim eu nec, iaculis hendrerit turpis. Vestibulum lobortis facilisis.</p>
                                                    <a href="#" className="kc-button kc-button-primary">BUY NOW</a>
                                                    <a href="#" className="kc-button kc-button-white">ADD TO CART</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-sale">SALE</div>
                                </div>
                                <div className="kc-product-wrap">
                                    <div className="box-product">
                                        <div className="uk-grid uk-grid-collapse">
                                            <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="thumbnai-product">
                                                    <a href="#"><img src="assets/images/product-list-2.jpg" alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="uk-width-medium-2-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="conten-product">
                                                    <h3>CREATIVE CHAIR YELLOW</h3>
                                                    <p>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                    </p>
                                                    <h5>$199<span>$250</span></h5>
                                                    <p className="text-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent velit odio, id porta orci vulputate a. Donec lorem purus, dignissim eu nec, iaculis hendrerit turpis. Vestibulum lobortis facilisis.</p>
                                                    <a href="#" className="kc-button kc-button-primary">BUY NOW</a>
                                                    <a href="#" className="kc-button kc-button-white">ADD TO CART</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kc-product-wrap">
                                    <div className="box-product">
                                        <div className="uk-grid uk-grid-collapse">
                                            <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="thumbnai-product">
                                                    <a href="#"><img src="assets/images/product-list-3.jpg" alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="uk-width-medium-2-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="conten-product">
                                                    <h3>CREATIVE CHAIR YELLOW</h3>
                                                    <p>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                    </p>
                                                    <h5>$199<span>$250</span></h5>
                                                    <p className="text-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent velit odio, id porta orci vulputate a. Donec lorem purus, dignissim eu nec, iaculis hendrerit turpis. Vestibulum lobortis facilisis.</p>
                                                    <a href="#" className="kc-button kc-button-primary">BUY NOW</a>
                                                    <a href="#" className="kc-button kc-button-white">ADD TO CART</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kc-product-wrap">
                                    <div className="box-product">
                                        <div className="uk-grid uk-grid-collapse">
                                            <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="thumbnai-product">
                                                    <a href="#"><img src="assets/images/product-list-4.jpg" alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="uk-width-medium-2-3 uk-width-small-1-1 uk-width-1-1">
                                                <div className="conten-product">
                                                    <h3>CREATIVE CHAIR YELLOW</h3>
                                                    <p>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                        <span className="uk-icon-star"></span>
                                                    </p>
                                                    <h5>$199<span>$250</span></h5>
                                                    <p className="text-padding">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent velit odio, id porta orci vulputate a. Donec lorem purus, dignissim eu nec, iaculis hendrerit turpis. Vestibulum lobortis facilisis.</p>
                                                    <a href="#" className="kc-button kc-button-primary">BUY NOW</a>
                                                    <a href="#" className="kc-button kc-button-white">ADD TO CART</a>
                                                </div>
                                            </div>
                                            <div className="product-sale">SALE</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagination">
                                <ul>
                                    <li><a href="#"><span className="icon left"></span></a></li>
                                    <li><a href="#"><span>1</span></a></li>
                                    <li><a href="#"><span>2</span></a></li>
                                    <li className="active"><a href="#"><span>3</span></a></li>
                                    <li><a href="#"><span>4</span></a></li>
                                    <li><a href="#"><span>5</span></a></li>
                                    <li><a href="#"><span className="icon right"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* END BEGIN PRODUCTS LIST
        <!-- BEGIN BLOCK DESIGNS --> */}
            <section className="designs sec-padding sec-background" style={{ padding: '50px 0' }}>
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>best designs</h3>
                        <p>Your trusted source for top-quality, eco-friendly modular furniture since 2022, where innovation meets customer satisfaction.</p>
                        <div className="border-line"></div>
                    </div>
                </div>
                <div data-uk-slideset="{animation: 'scale', medium:4, small:2}">
                    <div className="uk-flex uk-flex-center filter-design-sc">
                        <ul id="filter" className="filter">
                            {/* <li className="check-class kc-filter-all uk-active" data-uk-filter=""><a href="#">Kitchen</a></li> */}
                            <li className="check-class" data-uk-filter="house" ><a href="#">Modular Kitchen</a></li>
                            <li className="check-class" data-uk-filter="garacer" ><a href="#">Wardrobe</a></li>
                            <li className="check-class" data-uk-filter="pool" ><a href="#">Modern Beds</a></li>
                            {/* <li className="check-class" data-uk-filter="villa" ><a href="#">Villa</a></li> */}
                        </ul>
                    </div>
                    <div className="uk-slidenav-position content-design-sc">
                        <ul className="uk-slideset uk-grid uk-grid-collapse uk-flex-center uk-grid-width-1-1 uk-grid-width-medium-1-4 uk-grid-width-small-1-2">
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_5.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_6.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_7.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modular_Kitchen/image_8.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/1-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                               <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/2-1-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                            <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            {/* <li data-uk-filter="house" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/1-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="images/design/2-2-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-3-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/1-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="images/design/2-4-full.jpg" data-uk-lightbox="{group:'group-one'}"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_5.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_6.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="garacer" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_7.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Wardrobe/image_8.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="pool" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_1.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_2.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-uk-filter="pool" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_3.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/Modern_Beds/image_4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            {/* <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div> */}
                                              {/*  <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div>  */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li data-uk-filter="villa" className="design-item">
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/1-4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                               <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-overlay uk-overlay-hover uk-animation-hover">
                                    <img src="assets/images/design/2-4.jpg" alt="" />
                                    <div className="uk-overlay-panel uk-overlay-background uk-flex uk-flex-middle uk-flex-center uk-animation-scale-up">
                                        <div className="box">
                                            <div className="box-icon">
                                                <a href="#"><span className="icon uk-icon-link"></span></a>
                                                <a href="#"><span className="icon uk-icon-download"></span></a>
                                            </div>
                                             <div className="box-content">
                                                <h4>SMALL KITCHEN DESIGN</h4>
                                                <p>Idea Creative</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="uk-container uk-container-center pagination-design-sc">
                        <div className="uk-flex uk-flex-center uk-flex-middle">
                            <div className="slideset-arrow">
                                <a href="#" className="uk-slidenav uk-slidenav-previous hidden" data-uk-slideset-item="previous"></a>
                                <a href="#" className="uk-slidenav uk-slidenav-next hidden" data-uk-slideset-item="next"></a>
                                <ul id="has_class" className="uk-slideset-nav uk-dotnav uk-flex-center"></ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!-- END BEGIN BLOCK DESIGNS -->
        BEGIN CAROUSEL */}
            <section className="carousel sec-padding sec-background">
                <div className="uk-container uk-container-center">
                    <div className="uk-slidenav-position" data-uk-slider="{center:true, autoplay: true}">
                        <div className="uk-slider-container">
                            <ul className="uk-slider uk-grid-width-medium-1-5 uk-grid-width-smal-1-3 uk-grid-width-1-1">
                                <li><img src="assets/images/carousel/Image_1.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_2.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_3.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_4.png" alt="" /></li>
                                <li><img src="assets/images/carousel/Image_5.png" alt="" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END BEGIN CAROUSEL
        END BEGIN CONTAINER */}
        </div>
    )
}

export default Products
