import React from 'react'
import { Link } from 'react-router-dom'

function About() {
    return (
        <>
            <section className="inner-banner sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="box uk-clearfix">
                        <h2>about us</h2>
                        <ul className="uk-breadcrumb">
                            <li><Link to='/'>Home</Link></li>
                            <li><span>About Us</span></li>
                        </ul>
                    </div>
                </div>
            </section>

           
            {/* BEGIN DESIGN */}
            <section className="awesome-design sec-padding">
                <div className="design">
                    <div className="uk-container uk-container-center">
                        <div className="uk-grid uk-grid-collapse">
                            <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                <div className="left sec-title">
                                    <h3>AWESOME DESIGN</h3>
                                    <h6>Quality and Trust Since 2022</h6>
                                    <p>Elevate your living spaces to extraordinary heights with Unilex Interio, your trusted destination for cutting-edge modular furniture since 2022. As pioneers in adopting superior technology in India, we pride ourselves on being a name you can rely on. Our relentless pursuit of perfection drives us to push the boundaries of technology, resulting in visually stunning and highly efficient products, including modular kitchens, wardrobes, premium beds, and more. Your safety and satisfaction are our utmost priorities, and our dedicated support team is always ready to address your inquiries promptly and with humility.</p>
                                    {/* <div className="kc-button-left">
                                        <a href="#" className="kc-button kc-button-primary">BOOK DESIGN</a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="uk-width-medium-1-2 uk-width-small-1-1 uk-width-1-1">
                                <div className="right">
                                    <span className="rectangle"></span>
                                    <div className="box-wrapper uk-grid uk-grid-collapse">
                                        <div className="border-right border-bottom uk-width-medium-1-2 uk-width-small-1-2 uk-width-1-2 uk-flex uk-flex-middle">
                                            <div className="box">
                                                <h1>25</h1>
                                                <p>PROJECTS COMPLETE</p>
                                            </div>
                                        </div>
                                        <div className="border-bottom uk-width-medium-1-2 uk-width-small-1-2 uk-width-1-2 uk-flex uk-flex-middle">
                                            <div className="box">
                                                <h1>124</h1>
                                                <p>CLIENTS REGISTER</p>
                                            </div>
                                        </div>
                                        <div className="border-right uk-width-medium-1-2 uk-width-small-1-2 uk-width-1-2 uk-flex uk-flex-middle">
                                            <div className="box">
                                                <h1>10</h1>
                                                <p>BUSINESS PARTNER</p>
                                            </div>
                                        </div>
                                        <div className="uk-width-medium-1-2 uk-width-small-1-2 uk-width-1-2 uk-flex uk-flex-middle">
                                            <div className="box">
                                                <h1>12</h1>
                                                <p>ONGOING PROJECTS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END BEGIN DESIGN
        BEGIN HISTORY */}
            {/* <section className="history sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="uk-grid uk-flex uk-flex-center">
                        <div className="uk-width-medium-4-6 uk-width-small-1-1 uk-width-1-1">
                            <div className="sec-title">
                                <h3>OUR HISTORY</h3>
                                <p>Donec vitae turpis volutpat, eleifend dolor vitae, consectetur erat vestibulum dignissim mollis mauris.</p>
                                <div className="border-img"></div>
                            </div>
                        </div>
                        <div className="uk-width-medium-5-6 uk-width-small-1-1 uk-width-1-1">
                            <div className="box uk-grid uk-grid-collapse">
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-year uk-flex uk-flex-center uk-flex-middle">
                                        <div className="year">
                                            <h1>2004</h1>
                                            <span className="point"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-member uk-flex uk-flex-center uk-flex-middle">
                                        <div className="member">
                                            <span className="uk-icon-flag"></span>
                                            <h1>1000 MEMBERS</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-text uk-flex uk-flex-center uk-flex-middle">
                                        <div className="text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim maximus dolor, faucibus aliquet arcu. Curabitur neque metus, tempor ut mi sed, consectetur molestie sem. Suspendisse interdum tristique sagittis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box uk-grid uk-grid-collapse">
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-year uk-flex uk-flex-center uk-flex-middle">
                                        <div className="year">
                                            <h1>2010</h1>
                                            <span className="point"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-member uk-flex uk-flex-center uk-flex-middle">
                                        <div className="member">
                                            <span className="uk-icon-diamond"></span>
                                            <h1>4000 MEMBERS</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-text uk-flex uk-flex-center uk-flex-middle">
                                        <div className="text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim maximus dolor, faucibus aliquet arcu. Curabitur neque metus, tempor ut mi sed, consectetur molestie sem. Suspendisse interdum tristique sagittis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box uk-grid uk-grid-collapse">
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-year uk-flex uk-flex-center uk-flex-middle">
                                        <div className="year">
                                            <h1>2016</h1>
                                            <span className="point"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-member uk-flex uk-flex-center uk-flex-middle">
                                        <div className="member">
                                            <span className="uk-icon-trophy"></span>
                                            <h1>8000 MEMBERS</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-large-1-3 uk-width-medium-1-3">
                                    <div className="box-text uk-flex uk-flex-center uk-flex-middle">
                                        <div className="text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim maximus dolor, faucibus aliquet arcu. Curabitur neque metus, tempor ut mi sed, consectetur molestie sem. Suspendisse interdum tristique sagittis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* END BEGIN HISTORY
        BEGIN OUR SERVICES  */}
            <section className="our-service sec-padding">
                <div className="uk-container uk-container-center">
                    <div className="sec-title">
                        <h3>OUR SERVICES</h3>
                        <p>Discover Our Comprehensive Services: Elevating Spaces with Superior Modular Furniture and Unwavering Commitment to Quality Since 2022.</p>
                        <div className="border-img"></div>
                    </div>
                    <div className="uk-grid">
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-pencil"></span>
                                </div>
                                <div className="box-content">
                                    <h4>DESIGN & DRAWINGS</h4>
                                    <p>Designing Excellence: Unilex Interio, Your Trusted Source for Top-Quality Modular Furniture Across India.</p>
                                </div>
                            </div>
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-cubes"></span>
                                </div>
                                <div className="box-content">
                                    <h4>KITCHEN DESI</h4>
                                    <p>Elevate Your Kitchen Design with Unilex Interio: Where Quality Meets Innovation Across India</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-refresh"></span>
                                </div>
                                <div className="box-content">
                                    <h4>REBUILD DESIGN</h4>
                                    <p>Transforming Your Space: Unilex Interio, the Trusted Source for Innovative Modular Furniture Solutions Across India.</p>
                                </div>
                            </div>
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-dropbox"></span>
                                </div>
                                <div className="box-content">
                                    <h4>CLOUD SAVE</h4>
                                    <p>Elevate Your Space with Unilex Interio: Where Quality Meets Trust Since 2022.</p>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-medium-1-3 uk-width-small-1-1 uk-width-1-1">
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-cogs"></span>
                                </div>
                                <div className="box-content">
                                    <h4>CUSTOM DESIGN</h4>
                                    <p>Crafting Your Vision: Unilex Interio, Your Trusted Source for Custom Modular Furniture Solutions Across India.</p>
                                </div>
                            </div>
                            <div className="box-service">
                                <div className="box-icon">
                                    <span className="icon uk-icon-hand-peace-o"></span>
                                </div>
                                <div className="box-content">
                                    <h4>WEB DESIGN</h4>
                                    <p>Elevate Your Online Presence with Unilex Interio: Crafting Quality Web Design Solutions for Your Modular Furniture Business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About